import { render, staticRenderFns } from "./transfor.vue?vue&type=template&id=ba158976&scoped=true&"
import script from "./transfor.vue?vue&type=script&lang=js&"
export * from "./transfor.vue?vue&type=script&lang=js&"
import style0 from "./transfor.vue?vue&type=style&index=0&id=ba158976&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba158976",
  null
  
)

export default component.exports